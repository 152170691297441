<template>
    <v-container fluid class="p-4" style="padding-bottom:150px">
        <v-row>
            <v-col cols="12">
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>

                    <v-spacer></v-spacer>

                    <v-text-field
                        solo
                        clearable
                        class="border-12"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        @keyup.enter="getPullData()"
                        hide-details
                        style="max-width:400px"
                    ></v-text-field>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-5" style="padding-bottom:150px">
            <v-col cols="12">
                <v-card class="rounded-l elevation-5">
                    <v-card-title>
                        Sales Order 
                        <v-alert
                            text
                            color="success"
                            class="ml-4 mb-0 rounded-l"
                            v-show="display_alert"
                        >
                            <v-row
                                align="center"
                                no-gutters
                            >
                                <v-col cols="12">
                                    <span class="mr-5">
                                        {{this.selectOrders.length}} Selected
                                    </span>
                                    <v-btn
                                        color="success"
                                        outlined
                                        small
                                        class="mr-2"
                                    >
                                        Approve
                                    </v-btn>
                                    <v-btn
                                        color="error"
                                        outlined
                                        small
                                        class="mr-2"
                                    >
                                        Cancel Approve
                                    </v-btn>
                                    <v-btn
                                        color="success"
                                        outlined
                                        small
                                        class="mr-2"
                                    >
                                        Pending
                                    </v-btn>
                                    <v-btn
                                        color="error"
                                        outlined
                                        small
                                        class="mr-2"
                                    >
                                        Cancel Pending
                                    </v-btn>
                                    <v-btn
                                        color="success"
                                        outlined
                                        small
                                        class="mr-2"
                                    >
                                        To RM
                                    </v-btn>
                                    <v-btn
                                        icon
                                        color="success"
                                        @click="closeAlert()"
                                    >
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-2"
                        @click="getPullData()"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-2"
                        @click="dialog = true"
                        >
                            <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="success"
                        class="py-5"
                        to="/admin/kmb/transaction/sales-order/create"
                        >
                            Create New
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="orders"
                            :single-select="singleSelect"
                            @toggle-select-all="selectAll"
                            @item-selected="selectItem"
                            item-key="CustomerOrderNo"
                            show-select
                            class="elevation-1"
                        >
                        <template v-slot:[`item.ord_amt`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.ord_amt) }}
                        </template>
                        <template v-slot:[`item.JthTempo`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.JthTempo) }}
                        </template>
                        <template v-slot:[`item.credit_limit`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.credit_limit) }}
                        </template>
                        <template v-slot:[`item.TotFaktur`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.TotFaktur) }}
                        </template>  
                        <template v-slot:[`item.dt_approve1`]="{ item }">
                            <p v-if="item.dt_approve1 != '1900-01-01 00:00:00.000'">
                                {{item.dt_approve1}}
                            </p>
                        </template>  
                        <template v-slot:[`item.dt_approve2`]="{ item }">
                            <p v-if="item.dt_approve2 != '1900-01-01 00:00:00.000'">
                                {{item.dt_approve2}}
                            </p>
                        </template>  
                        <template v-slot:[`item.dtRs`]="{ item }">
                            <p v-if="item.dtRs != '1900-01-01 00:00:00.000'">
                                {{item.dtRs}}
                            </p>
                        </template>                        
                        <template v-slot:[`item.action`]="{ item }">
                            <v-menu
                                bottom
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item @click="getDetail(item)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Detail</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Edit</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Copy Order</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-printer-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Preview SP Internal</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-printer-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Preview Cust SP</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-printer-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Display Credit Usage</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-printer-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Display Sales Visit</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="1000px"
                >
                    <v-card class="p-2 rounded-l">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    Filter Sales Order 
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row class="mt-2 mb-5">
                                    <v-col class="col-12" sm="6" md="2">
                                        <p class="blue-lcd mb-1">Region</p>   
                                        <v-autocomplete
                                            dense
                                            solo
                                            clearable
                                            v-model="region"
                                            :items="regions"
                                            item-value="region"
                                            item-text="region"
                                            class="ma-0 pa-0 border-12"
                                            @change="(event) => getOffice(event)"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="6">
                                        <p class="blue-lcd mb-1">Offfice</p>   
                                        <v-autocomplete
                                            dense
                                            solo
                                            clearable
                                            v-model="office"
                                            :items="offices"
                                            item-value="office_id"
                                            item-text="office"
                                            class="ma-0 pa-0 border-12"
                                            @change="(event) => getSales(event)"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="2">
                                        <p class="blue-lcd mb-1">Division</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="division"
                                            :items="divisions"
                                            item-value="class"
                                            item-text="descr"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="2">
                                        <p class="blue-lcd mb-1">Approval</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="approve"
                                            :items="approves"
                                            item-value="value"
                                            item-text="text"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="3">
                                        <p class="blue-lcd mb-1">Mill</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="mill"
                                            :items="mills"
                                            item-value="mill_id"
                                            item-text="mill_name"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="3">
                                        <p class="blue-lcd mb-1">Sales ID</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="sales"
                                            :items="saleses"
                                            item-value="SalesId"
                                            item-text="NamaSales"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="6">
                                        <p class="blue-lcd mb-1">Customer ID</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="customer"
                                            :items="customers"
                                            :search-input.sync="searchCustomer"
                                            cache-items
                                            hide-no-data
                                            item-value="CustomerId"
                                            item-text="NamaCustomer"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="6">
                                        <p class="blue-lcd mb-1">Order ID</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="order_id"
                                            :items="order_ids"
                                            :search-input.sync="searchOrder"
                                            cache-items
                                            hide-no-data
                                            item-value="CustomerOrderNo"
                                            item-text="NamaCustomer"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="3">
                                        <p class="blue-lcd mb-1">Payterm</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="payterm"
                                            :items="payterms"
                                            item-value="pay_term_id"
                                            item-text="pay_term_desc"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="3">
                                        <p class="blue-lcd mb-1">Status</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="status"
                                            :items="statuses"
                                            item-value="value"
                                            item-text="text"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="6">
                                        <p class="blue-lcd mb-1">Start Date</p>   
                                        <v-menu
                                            ref="modal"
                                            v-model="modal"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                solo
                                                dense
                                                v-model="date_from"
                                                persistent-hint
                                                append-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="date_from"
                                                no-title
                                                @input="modal = false"
                                            ></v-date-picker>
                                        </v-menu>
                                        
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="6">
                                        <p class="blue-lcd mb-1">End Date</p> 
                                        <v-menu
                                            ref="modal_to"
                                            v-model="modal_to"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                solo
                                                dense
                                                v-model="date_to"
                                                persistent-hint
                                                append-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="date_to"
                                                no-title
                                                @input="modal_to = false"
                                            ></v-date-picker>
                                        </v-menu> 
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="end">
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l mr-2" color="#fff" @click="dialog = false">Cancel</v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l text-white" color="primary" @click="filter()">OK</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Kencana Maju Bersama',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Transaction',
                disabled: true,
                },
                {
                text: 'Sales Order',
                disabled: true,
                href: '/admin/kmb/transaction/sales-order',
                }
            ],
            loading: false,
            display: 'none',
            options: {},
            search: '',
            singleSelect: false,
            selected: [],
            headers: [
                {
                    text: 'Order ID',
                    align: 'start',
                    value: 'CustomerOrderNo', 
                    align:'center'},
                { text: 'Order Date', value: 'TglPesan' , align:'center'},
                { text: 'Sales ID', value: 'SalesId' , align:'center'},
                { text: 'Customer', value: 'namacustomer' , align:'center'},
                { text: 'TOP', value: 'pay_term' , align:'center'},
                { text: 'Ship From', value: 'Produksi' , align:'center'},
                { text: 'Order Amt', value: 'ord_amt' , align:'center'},
                { text: 'OD', value: 'JthTempo' , align:'center'},
                { text: 'Overdue Remark', value: 'omremark' },
                { text: 'Credit Limit', value: 'credit_limit' },
                { text: 'Invoice Record', value: 'TotFaktur' },
                { text: 'Approval BM', value: 'dt_approve1' },
                { text: 'Approval RM', value: 'dt_approve2' },
                { text: 'Approval Finance (CBD)', value: 'dtRs' },
                { text: '', value: 'action' , align:'center'},
            ],
            orders: [],
            selectOrders: [],
            display_alert: false,
            dialog: false,
            regions: [],
            region: '',
            offices: [],
            office: '',
            divisions: [],
            division: '',
            approves: [
                {
                    value : "approve1",
                    text : "1"
                },
                {
                    value : "approve2",
                    text : "2"
                }
            ],
            approve: '',
            mills: [],
            mill: '',
            saleses: [],
            sales: '',
            customers: [],
            customer: '',
            order_ids: [],
            order_id: '',
            payterms: [
                {
                    pay_term_id: 'all',
                    pay_term_desc: 'ALL'
                },
                {
                    pay_term_id: 'cash',
                    pay_term_desc: 'CASH'
                },
                {
                    pay_term_id: 'tt',
                    pay_term_desc: 'TT'
                }
            ],
            payterm: '',
            statuses: [],
            status: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            searchCustomer: null,
            searchOrder: null
        }
    },
    mounted(){
        this.getPullData()
        this.getRegion()
        this.getOffice()
        this.getSales()
        this.initialize()
    },
    methods:{ 
        async initialize(){
            const respData = await backendApi.fetchCore(`/api/kmb/transaction/sales-order`, null, false, false, false)
            if (respData.status === 200) {
                this.divisions = respData.data.division
                this.mills = respData.data.mill
            }
        },
        async getRegion(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/region?region=${this.region ? this.region : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.regions = respData.data
            }
        },
        async getOffice(){
            this.offices = []
            const respData = await backendApi.fetchCore(`/v2/master/kmb/office?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.offices = respData.data
            }
        },
        async getSales(){
            this.saleses = []
            const respData = await backendApi.fetchCore(`/v2/master/kmb/sales?office_id=${this.office ? this.office : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.saleses = respData.data
            }
        },
        async getCustomer(id){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/customer?customer=${id ? id : ''}`, null, false, false, false)
            this.customers = []
            if (respData.status === 200) {
                this.customers = respData.data
            }
        },
        async getOrder(id){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/order?orderid=${id ? id : ''}&sales=${this.sales ? this.sales : ''}&customer=${this.customer ? this.customer : ''}`, null, false, false, false)
            this.order_ids = []
            if (respData.status === 200) {
                this.order_ids = respData.data
            }
        },
        filter() {
            this.dialog = false
            this.getPullData()
        },
        async getPullData(){
            this.$store.dispatch('setOverlay', true)

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            
            const respData = await backendApi.fetchCore(`/v2/kmb/sales_order?region=${this.region ? this.region : ''}&office=${this.office ? this.office : ''}&divisi=${this.division ? this.division : ''}&approve=${this.approve ? this.approve : ''}&mill=${this.mill ? this.mill : ''}&sales=${this.sales ? this.sales : ''}&customer=${this.customer ? this.customer : ''}&order=${this.order_id ? this.order_id : ''}&pay_term=${this.payterm ? this.payterm : ''}&start_date=${start_date}&end_date=${end_date ? end_date : ""}&search=${this.search ? this.search : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)

                this.orders = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }   

        },
        getDetail(item){

        },
        closeAlert(){
            this.display_alert = false
        },
        selectAll(orders){
            if (orders.value === true) {
                this.display_alert = true
                this.selectOrders = orders.items
            } else {
                this.display_alert = false
                this.selectOrders = []
            } 
        },
        selectItem(order){
            if (order.value === true) {
                this.display_alert = true
                this.selectOrders.push(order.item)
            } else {
                for (let index = 0; index < this.selectOrders.length; index++) {
                    if (this.selectOrders[index].CustomerOrderNo === order.item.CustomerOrderNo) {
                        this.selectOrders.splice(index, 1);
                        if (this.selectOrders.length <= 0) {
                            this.display_alert = false
                        }
                    }
                }
            } 
        },
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display == 'block') {
                    // this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        },
        searchCustomer (val) {
            val && val !== this.customer && this.getCustomer(val)
        },
        searchOrder (val) {
            val && val !== this.order_id && this.getOrder(val)
        },
    }
}
</script>